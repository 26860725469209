<template>
  <div class="shop">
    <!-- 輪播圖 -->
    <div class="tw-carousel mb-3" v-if="pictureLinks.length > 0">
      <Carousel
        :autoplay="5000"
        :items-to-show="currentViewSize.width >= 768 ? 3 : 1"
        :wrap-around="true"
      >
        <Slide v-for="data in pictureLinks" :key="data.id">
          <div class="carousel__item">
            <div
              class="pointer carousel-img"
              :style="`background: url(${data.imgUrl}) no-repeat center center; background-size: contain;`"
              @click="carouselUrl(data)"
            ></div>
          </div>
        </Slide>
        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </div>
    <!-- 下方內容 -->
    <div class="shop-content">
      <!-- 分類圖 -->
      <div class="category-img" v-if="currentCategoryBanner">
        <img :src="currentCategoryBanner" alt="" />
      </div>
      <!-- 內容 -->
      <div class="tw-container">
        <!-- <= 919px 的分類標籤 -->
        <div class="category-lable pointer" @click="showCategoryList">
          分類選單
        </div>
        <!-- 分類與子標題 -->
        <div>
          <!-- 分類名 -->
          <div class="d-flex align-items-center mb-2">
            <img
              src="@/assets/website-use/poke_R.png"
              alt="poke_r"
              style="width: 40px; height: 40px"
            />
            <span class="tw-text-size20 fw-bolder">{{
              currentCategoryName
            }}</span>
          </div>
          <!-- 子標題 -->
          <div v-if="currentCategorySubtitle">
            <i class="bi bi-arrow-return-right"></i>
            <span class="mt-2 text-secondary fw-bolder tw-text-size14">
              {{ currentCategorySubtitle }}
            </span>
          </div>
        </div>
        <!-- 商品與分類內容 -->
        <div class="merh-area">
          <!-- 分類區塊 -->
          <div id="CategoryBlock" class="category-block" @click="removeClass">
            <!-- 搜尋 -->
            <div>
              <div class="search input-group my-2">
                <input
                  type="text"
                  class="form-control d-inline-block"
                  placeholder="輸入欲搜尋商品名稱"
                  v-model="search"
                  @click.stop=""
                />
                <button
                  class="btn btn-light border"
                  type="button"
                  id="button-addon2"
                  @click.stop="searchMerchandise"
                >
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </div>
            <!-- 分類列表 -->
            <ul class="category-area">
              <li
                class="pointer"
                v-for="data in categories"
                :key="data.id"
                @click="changeCategory(data)"
              >
                <p>{{ data.name }}</p>
                <p class="subtitle" v-if="data.subtitle">
                  <i class="bi bi-arrow-return-right"></i>{{ data.subtitle }}
                </p>
              </li>
              <li class="pointer border-0" @click="changeCategory(0)">
                未分類
              </li>
            </ul>
            <!-- todo Oldmoon 新增測試用 -->
            <div class="mt-3">
              <!-- 前往新版個人賣場 -->
              <router-link
                class="me-1 mb-1 tw-btn tw-btn-success tw-btn-hover"
                :to="`/customer/store/${storeId}/Shop?category=${$route.query.category}`"
                target="_blank"
              >
                前往新版個人賣場
              </router-link>
            </div>
          </div>
          <!-- 商品區塊 -->
          <template v-if="!areaLoading">
            <div class="merh-card-area row" v-if="merchandiseList.length > 0">
              <div
                :id="`Merchandise${data.id}`"
                class="col-xl-3 col-md-4 col-6 p-0 mb-3 px-1"
                v-for="data in merchandiseList"
                :key="data.id"
              >
                <!-- 商品卡片 -->
                <div class="merch-card p-2" @click="goToOrder(data)">
                  <div
                    class="deadline-area fw-bolder text-break"
                    v-if="data.deadLineTime"
                  >
                    <CountDown
                      :id="data.id"
                      :time="data.deadLineTime"
                    ></CountDown>
                  </div>
                  <!-- 圖 -->
                  <div
                    class="merch-img mb-2"
                    :style="`background: url(${data.frontImg}) no-repeat center center; background-size: contain;`"
                    v-if="data.frontImg"
                  ></div>
                  <img
                    class="merch-img mb-2"
                    src="@/assets/other-images/noImg.png"
                    v-else
                  />
                  <!-- 分隔線 -->
                  <div
                    class="d-flex justify-content-between align-items-center mb-2"
                  >
                    <hr class="m-0 p-0 w-100" />
                    <!-- 販售狀態 -->
                    <div class="fw-bolder text-center d-inline-block">
                      <p
                        class="text-success alert-success p-1"
                        v-if="data.sellState === 'CANPREORDER'"
                        style="width: 60px"
                      >
                        可預購
                      </p>
                      <p
                        class="text-primary alert-primary p-1"
                        v-else-if="data.sellState === 'STOCKONLY'"
                        style="width: 80px"
                      >
                        僅供現貨
                      </p>
                      <p
                        class="text-danger alert-danger p-1"
                        v-else
                        style="width: 50px"
                      >
                        限量
                      </p>
                    </div>
                  </div>
                  <!-- 內容 -->
                  <div class="tw-text-size14">
                    <ul>
                      <!-- 商品名 -->
                      <li :title="data.name" class="fw-bolder mb-1 merch-name">
                        {{ data.name }}
                      </li>
                      <!-- 商品標籤 -->
                      <li
                        class="mb-2 text-success fw-bolder merch-lable"
                        :title="data.label"
                      >
                        {{ data.label }}
                      </li>
                      <!-- 售價 -->
                      <li class="mb-2">
                        <span v-if="merchCardShowPriceMode === 'normal'">
                          <span v-if="data.lowerPrice === data.higherPrice"
                            >${{ data.lowerPrice }}</span
                          >
                          <span v-else
                            >${{ data.lowerPrice }} ~ ${{
                              data.higherPrice
                            }}</span
                          >
                        </span>
                        <span v-else>
                          <span v-if="data.lowerPrice === data.higherPrice"
                            >${{ data.lowerPrice }}</span
                          >
                          <span v-else
                            ><del class="me-2">${{ data.higherPrice }}</del>
                            <span
                              class="fw-bolder text-danger"
                              style="font-size: 17px"
                              >${{ data.lowerPrice }}</span
                            ></span
                          >
                        </span>
                      </li>
                      <!-- 成團數 / 庫存 -->
                      <li class="d-flex fw-bolder mb-2">
                        <span
                          class="border border-danger text-danger me-2"
                          v-if="data.startSaleQuantity"
                          style="padding: 2px"
                          >{{ data.startSaleQuantity }}單成團</span
                        >
                        <span
                          class="border border-primary text-primary"
                          style="padding: 2px"
                          v-if="showStock"
                          >庫存: {{ data.remainingStock }}</span
                        >
                      </li>
                    </ul>
                    <!-- 最底下資訊 -->
                    <div class="info-area text-secondary">
                      <!-- 熱賣 -->
                      <p class="fw-bolder" v-if="data.hotQuantity">
                        已售出: {{ data.hotQuantity }}
                      </p>
                      <!-- 到貨時間 -->
                      <p
                        class="text-break d-inline-block fw-bolder"
                        v-if="merchCardShowArrivalTime && data.arrivalTime"
                      >
                        到貨:{{
                          $methods
                            .moment(data.arrivalTime)
                            .format("YYYY-MM-DD HH:mm:ss")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="w-100 d-flex justify-content-center align-items-center"
              v-else
            >
              <p class="alert alert-danger fw-bolder d-inline-block">
                目前分類未有任何商品呦 ^^
              </p>
            </div>
          </template>
          <!-- 請稍後字樣 -->
          <div
            class="d-flex justify-content-center align-items-center w-100"
            v-else
          >
            <AreaLoading></AreaLoading>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
// components
import CountDown from '@/components/tools/CountDown.vue'
// api
import { parseTokenToGetTheParticipant } from '@/methods/API/parseTokenToGetTheParticipant.js'

export default {
  components: {Carousel, Slide, Pagination, Navigation, CountDown},
  data() {
    return {
      storeId: 0,
      serverToken: '',
      storeInfo: {},
      pictureLinks: [],
      areaLoading: false,
      // 紀錄目前視窗寬高
      currentViewSize: {
        width: 0,
        height: 0,
      },
      participantInfo: {},
      // 商品分類
      categories: [],
      currentCategoryId: 0,
      currentCategoryName: '未分類',
      currentCategorySubtitle: '',
      currentCategoryBanner: null,
      // 商品
      merchandiseList: [],
      // 搜尋
      search: '',
    }
  },
  created() {
    this.initialization()
    this.getPrefer()
    this.getParticipantInfo()
  },
  mounted() {
    // 紀錄目前視窗寬高
    this.currentViewSize.width = window.innerWidth;
    this.currentViewSize.height = window.innerHeight;
    // 監聽
    window.onresize = () => {
      this.currentViewSize.width = window.innerWidth;
      this.currentViewSize.height = window.innerHeight;
    };
  },
  unmounted() {
    this.breakOff()
  },
  watch: {
    currentCategoryId(val, oldVal) {
      if (val !== oldVal) {
        if (val > 0) this.getMerhInCategory()
        else if (val === 0) this.getMerchandises()
      }
    },
  },
  computed: {
    // 偏好 > 商品小卡價格呈現
    merchCardShowPriceMode() {
      let merchCard = 'normal'
      this.$store.state.Prefer.p_shop.merchCard.forEach(item => {
        if (item.selected) merchCard = item.value
      })
      return merchCard
    },
    // 偏好 > 熱賣商品
    merchCardShowSellQuantity() {
      let sellQuantity = 'doNotShow'
      this.$store.state.Prefer.p_shop.sellQuantity.forEach(item => {
        if (item.selected) sellQuantity = item.value
      })
      return sellQuantity
    },
    // 偏好 > 顯示到貨時間
    merchCardShowArrivalTime() {
      return this.$store.state.Prefer.p_shop.showArrivalTime[0].selected
    },
    // 偏好 > 顯示庫存
    showStock() {
      return this.$store.state.Prefer.p_shop.showStock[0].selected
    },
  },
  methods: {
    initialization() {
      this.storeId = this.$route.params.storeId
      this.serverToken = this.$methods.getCookie('serverToken')
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 取得 participantInfo
    getParticipantInfo() {
      const result = parseTokenToGetTheParticipant(this.storeId, this.serverToken)
      result.then(res => {
        console.log(res)
        if (res.code === '200') {
          const participantInfo = res.data
          this.participantInfo = participantInfo
          // 檢查此顧客是否有權限進入
          if (this.participantInfo.deleted || this.participantInfo.forbidden) {
            alert('您尚未有權限進入賣場，可能被賣家刪除或禁止')
            return this.$router.push({name: 'C_Overview'})
          }
          this.getStoreInfo()
        }
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取得賣場資訊
    getStoreInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getStorePictureLinks,getMerchClassifications{getCustomerGroups}}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.storeInfo = storeInfo
            storeInfo.storePictureLinks.forEach(img => { img.imgUrl = `https://i.imgur.com/${img.imageHash}m.jpg` })
            vm.pictureLinks = storeInfo.storePictureLinks
            // 檢查是否關閉個人賣場
            if (!storeInfo.opened) {
              vm.$methods.switchLoading('hide')
              alert('賣場已關閉')
              return vm.$router.push({name: 'C_Overview'})
            }
            vm.sortOutCategories()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理商品分類
    sortOutCategories() {
      this.categories = []
      const now = new Date() * 1
      const p_customerGroupId = this.participantInfo.customerGroupId
      const p_storeVipId = this.participantInfo.storeVipId ? this.participantInfo.storeVipId : 0
      this.storeInfo.merchClassifications.forEach(item => {
        const openInterval = item.openInterval
        const enableTime = item.enableTime
        const expirationTime = item.expirationTime
        const categroyVipId = item.storeVipId ? item.storeVipId : 0
        const hidden = item.hidden
        let matchTheTimeInterval = true
        if (openInterval && enableTime && expirationTime) {
          matchTheTimeInterval = enableTime <= now && now <= expirationTime
        }
        let matchCustomerGroup = true
        if (p_customerGroupId && item.customerGroups.length > 0) {
          matchCustomerGroup = item.customerGroups.some(group => {
            return group.id === p_customerGroupId
          })
        } else if (!p_customerGroupId && item.customerGroups.length > 0) {
          matchCustomerGroup = false
        }
        let matchVipId = p_storeVipId >= categroyVipId
        console.log(matchTheTimeInterval,matchCustomerGroup,matchVipId,!hidden)
        if (this.participantInfo.authority === 'CUSTOMER') {
          if (matchTheTimeInterval && matchCustomerGroup && matchVipId && !hidden) this.categories.push(item)
        } else {
          if (matchTheTimeInterval && !hidden) this.categories.push(item)
        }
      });
      // 檢查 query 是否有分類 ID
      this.checkQuery()
    },
    // 檢查 query 是否有分類 ID
    checkQuery() {
      if (!this.$route.query.category) {
        // 路由沒有分類 ID
        if (this.categories.length > 0) {
        this.currentCategoryId = this.categories[0].id
        this.currentCategoryName = this.categories[0].name
        this.currentCategorySubtitle = this.categories[0].subtitle
        this.currentCategoryBanner = this.categories[0].imageHash ? `https://i.imgur.com/${this.categories[0].imageHash}.jpg` : null
        } else {
          this.currentCategoryId = 0
          this.getMerchandises()
        }
        this.$router.replace(`/customer/store/${this.storeId}/oldshop?category=${this.currentCategoryId}`)
      } else {
        // 若路由已經有分類 ID
        if (parseInt(this.$route.query.category) > 0) {
          const hasCategoryId = this.categories.some(item => {
            if (item.id === parseInt(this.$route.query.category)) {
              this.currentCategoryId = item.id
              this.currentCategoryName = item.name
              this.currentCategorySubtitle = item.subtitle
              this.currentCategoryBanner = item.imageHash ? `https://i.imgur.com/${item.imageHash}.jpg` : null
              return true
            }
          })
          // 如果 ID 已經不存在的話，就預設抓未分類
          if (!hasCategoryId) {
            this.currentCategoryId = 0
            this.getMerchandises()
            this.$router.replace(`/customer/store/${this.storeId}/oldshop?category=0`)
          }
        } else if (parseInt(this.$route.query.category) === 0) {
          this.getMerchandises()
        }
      }
    },
    // 從分類取得商品
    getMerhInCategory() {
      this.areaLoading = true
      this.$methods.switchLoading('show')
      const vm = this
      const getMerchandisesApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      let methods = '{getMerchandise{getMerchandisePictureLinks,getMerchandiseStyles{getMerchandiseDiscounts},getMerchOrders}}'
      // 顯示庫存偏好
      if (this.showStock) methods = '{getMerchandise{generateMerchandiseStylesStockJsonNode,getMerchandisePictureLinks,getMerchandiseStyles{getMerchandiseDiscounts},getMerchOrders}}'
      const now = parseInt(new Date() * 1 / 1000)
      let query = {"columns":[{"columnName":"id","siteObjectType":21},{"columnName":"ReleaseTime","siteObjectType":16}],"distinct":true,"group":{"groups":[{"column":{"columnName":"MerchClassificationId","siteObjectType":21},"operator":{"operator":"=","type":1,"value":this.currentCategoryId}},{"column":{"columnName":"deleted","siteObjectType":16},"operator":{"operator":"=","type":1,"value":false}},{"column":{"columnName":"ready","siteObjectType":16},"operator":{"operator":"=","type":1,"value":true}},{"groups":[{"column":{"columnName":"deadLineTime","siteObjectType":16},"operator":{"operator":">","type":1,"value":{"name":`from_unixtime(${now})`}}},{"column":{"columnName":"deadLineTime","siteObjectType":16},"operator":{"operator":true,"type":4,"value":[]}}],"operator":2}],"operator":1},"joins":[{"leftColumn":{"columnName":"merchandiseId","siteObjectType":21},"rightColumn":{"columnName":"id","siteObjectType":16},"type":0}],"orders":[{"asc":false,"column":{"columnName":"ReleaseTime","siteObjectType":16}}],"siteObjectType":21,"sqlCommandType":1}
      let p_storeVipId = this.participantInfo.storeVipId ? this.participantInfo.storeVipId : 0
      if (this.participantInfo.authority === 'CUSTOMER') {
        query.group.groups.push({
          "groups": [{
              "column": {
                  "columnName": "StoreVipId",
                  "siteObjectType": 16
              },
              "operator": {
                  "operator": "<=",
                  "type": 1,
                  "value": {
                      "name": p_storeVipId
                  }
              }
            },{
              "column": {
                  "columnName": "StoreVipId",
                  "siteObjectType": 16
              },
              "operator": {
                  "operator": true,
                  "type": 4,
                  "value": []
              }
            }
          ],
          "operator": 2
        })
      }
      const data = [
        {
          query: query,
          methods: methods,
          pagination: {
            limit: 200
          }
        }
      ]
      this.getMerhInCategoryFn = $.ajax({
        type: 'POST',
        async: true,
        url: getMerchandisesApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchClassificationLinks = res.data[0].objects
            const paging = res.data[0].paging
            vm.merchandiseList = []
            const merchArray = []
            merchClassificationLinks.forEach(item => { merchArray.push(item.merchandise) })
            vm.sortOutMerchandises(merchArray, paging)
          }
        },
        error: function(err) {
          console.log(err)
          if (!err.responseJSON) return
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 未分類時直接取得賣場所有未分類商品
    getMerchandises() {
      this.areaLoading = true
      this.$methods.switchLoading('show')
      const vm = this
      const getMerchandisesApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      let methods = '{getMerchandisePictureLinks,getMerchandiseStyles{getMerchandiseDiscounts},getMerchOrders}'
      // 顯示庫存偏好
      if (this.showStock) methods = '{generateMerchandiseStylesStockJsonNode,getMerchandisePictureLinks,getMerchandiseStyles{getMerchandiseDiscounts},getMerchOrders}'
      const now = parseInt(new Date() * 1 / 1000)
      let query = {"columns":[{"columnName":"id","siteObjectType":16}],"group":{"groups":[{"column":{"columnName":"storeId","siteObjectType":16},"operator":{"operator":"=","type":1,"value":this.storeId}},{"column":{"columnName":"id","siteObjectType":16},"operator":{"operator":"not in","type":1,"value":{"name":`(select merchandise.id from merchandise inner join merchclassificationlink on merchandise.id=merchclassificationlink.merchandiseId inner join merchclassification on merchclassificationlink.merchClassificationId=merchclassification.id where merchandise.storeId=${this.storeId} and (select count(merchclassificationlink.id) from merchclassificationlink where merchclassificationlink.merchandiseId=merchandise.id)>0)`}}},{"column":{"columnName":"deleted","siteObjectType":16},"operator":{"operator":"=","type":1,"value":false}},{"column":{"columnName":"ready","siteObjectType":16},"operator":{"operator":"=","type":1,"value":true}},{"groups":[{"column":{"columnName":"deadLineTime","siteObjectType":16},"operator":{"operator":">","type":1,"value":{"name":`from_unixtime(${now})`}}},{"column":{"columnName":"deadLineTime","siteObjectType":16},"operator":{"operator":true,"type":4,"value":[]}}],"operator":2}],"operator":1},"siteObjectType":16,"sqlCommandType":1}
      let p_storeVipId = this.participantInfo.storeVipId ? this.participantInfo.storeVipId : 0
      if (this.participantInfo.authority === 'CUSTOMER') {
        query.group.groups.push({
          "groups": [{
              "column": {
                  "columnName": "StoreVipId",
                  "siteObjectType": 16
              },
              "operator": {
                  "operator": "<=",
                  "type": 1,
                  "value": {
                      "name": p_storeVipId
                  }
              }
            },{
              "column": {
                  "columnName": "StoreVipId",
                  "siteObjectType": 16
              },
              "operator": {
                  "operator": true,
                  "type": 4,
                  "value": []
              }
            }
          ],
          "operator": 2
        })
      }
      const data = [
        {
          query: query,
          methods: methods,
          pagination: {
            limit: 200
          }
        }
      ]
      this.getMerchandisesFn = $.ajax({
        type: 'POST',
        async: true,
        url: getMerchandisesApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchInfo = res.data[0].objects
            const paging = res.data[0].paging
            vm.merchandiseList = []
            vm.sortOutMerchandises(merchInfo, paging)
          }
        },
        error: function(err) {
          console.log(err)
          if (!err.responseJSON) return
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 切換分類
    changeCategory(category) {
      this.breakOff()
      if (category === 0) {
        this.currentCategoryId = 0
        this.currentCategoryName = '未分類'
        this.currentCategorySubtitle = ''
        this.currentCategoryBanner = null
        this.$router.replace(`/customer/store/${this.storeId}/oldshop?category=0`)
      } else {
        this.currentCategoryId = category.id
        this.currentCategoryName = category.name
        this.currentCategorySubtitle = category.subtitle
        this.currentCategoryBanner = category.imageHash ? `https://i.imgur.com/${category.imageHash}.jpg` : null
        this.$router.replace(`/customer/store/${this.storeId}/oldshop?category=${category.id}`)
      }
    },
    // 整理商品
    sortOutMerchandises(merchArray, paging) {
      this.sortOutMerchandisesFn = setTimeout(() => {
        // 整理商品列表
        const now = new Date() * 1
        const p_storeVipId = this.participantInfo.storeVipId ? this.participantInfo.storeVipId : 0
        // 若 query 有 merchandiseId，則先檢查是否有在這個 "分類內"
        let queryMerchIdIsInThisClassified = 0
        console.log(merchArray)
        merchArray.forEach(item => {
          if (this.$route.query && this.$route.query.merchandiseId && this.$route.query.merchandiseId == item.id) queryMerchIdIsInThisClassified = item.id
          // 商品圖
          item.frontImg = null
          item.merchandisePictureLinks.some(img => {
            if (img.front) return item.frontImg = `https://i.imgur.com/${img.imageHash}.jpg`
          })
          // 價格呈現
          item.lowerPrice = item.merchandiseStyles[0].price
          item.higherPrice = item.merchandiseStyles[0].price
          if (this.merchCardShowPriceMode === 'normal') {
            item.merchandiseStyles.forEach(style => {
              if (style.hierarchy === 0) {
                item.lowerPrice = item.lowerPrice <= style.price ? item.lowerPrice : style.price
                item.higherPrice = item.higherPrice <= style.price ? style.price : item.higherPrice
              }
            })
          } else {
            item.merchandiseStyles.forEach(style => {
              if (style.hierarchy === 0) {
                item.lowerPrice = item.lowerPrice <= style.price ? item.lowerPrice : style.price
                item.higherPrice = item.higherPrice <= style.price ? style.price : item.higherPrice
                style.merchandiseDiscounts.forEach(discount => {
                  const discountVipId = discount.storeVipId ? discount.storeVipId : 0
                  if (p_storeVipId >= discountVipId) {
                    item.lowerPrice = item.lowerPrice <= discount.price ? item.lowerPrice : discount.price
                    item.higherPrice = item.higherPrice <= discount.price ? discount.price : item.higherPrice
                  }
                })
              }
            })
          }
          // 熱賣數量呈現
          item.hotQuantity = null
          if (this.merchCardShowSellQuantity !== 'doNotShow') {
            const merchCardShowSellQuantity = parseInt(this.merchCardShowSellQuantity)
            let quantity = 0
            item.merchOrders.forEach(order => {
              quantity += order.quantity
            })
            if (quantity >= merchCardShowSellQuantity) item.hotQuantity = quantity
          }
          // 顯示庫存偏好
          item.remainingStock = 0
          if (this.showStock) {
            item.merchandiseStylesStockJsonNode.forEach(stockItem => {
              if (stockItem.stock) {
                if (item.sellState === 'CANPREORDER' && stockItem.stock.stockOnlyQuantity > 0) item.remainingStock += stockItem.stock.stockOnlyQuantity
                else if (item.sellState === 'STOCKONLY' && stockItem.stock.stockOnlyQuantity > 0) item.remainingStock += stockItem.stock.stockOnlyQuantity
                else if (item.sellState === 'LIMITED' && Math.max(stockItem.stock.limitedQuantity - stockItem.stock.merchOrderQuantity, 0) > 0) item.remainingStock += Math.max(stockItem.stock.limitedQuantity - stockItem.stock.merchOrderQuantity, 0)
              }
            })
          }
          // 判斷是否顯示
          const deadLineTime = item.deadLineTime
          const deleted = item.deleted
          const ready = item.ready
          const storeVipId = item.storeVipId ? item.storeVipId : 0
          let matchDeadLineTime = true
          if (deadLineTime && now >= deadLineTime) matchDeadLineTime = false
          let matchVipId = p_storeVipId >= storeVipId
          if (this.participantInfo.authority === 'CUSTOMER') {
            if (!deleted && ready && matchDeadLineTime && matchVipId) this.merchandiseList.push(item)
          } else {
            if (!deleted && ready && matchDeadLineTime) this.merchandiseList.push(item)
          }
        })
        this.merchandiseList.sort((a, b) => {
          const a1 = a.releaseTime
          const b1 = b.releaseTime
          return b1 - a1
        })
        // 處理分頁
        if (paging && paging.current !== paging.total) {
          this.$api.getNextSiteObjects(paging.next).then(res => {
            const merchClassificationLinks = res.data[0].objects
            const paging = res.data[0].paging
            const merchArray = []
            console.log('merchClassificationLinks')
            console.log(merchClassificationLinks)
            merchClassificationLinks.forEach(item => {
              if (this.currentCategoryId === 0) merchArray.push(item)
              else merchArray.push(item.merchandise)
            })
            this.sortOutMerchandises(merchArray, paging)
          }).catch(err => {
            console.log(err)
            this.SweetAlert('other', err.responseJSON.message)
          })
        }
        console.log(this.merchandiseList)

        this.areaLoading = false
        this.$methods.switchLoading('hide')
        // 移動到指定商品
        let checkTimes = 0
        this.moveToSpecifiedLocation(checkTimes, queryMerchIdIsInThisClassified)
        }, 100)
    },
    // 移動到指定商品
    moveToSpecifiedLocation(checkTimes, queryMerchIdIsInThisClassified) {
      setTimeout(() => {
        // 若確定商品 ID 存在在此分類內 (queryMerchIdIsInThisClassified > 0) 在執行
        if (this.$route.query && this.$route.query.merchandiseId && checkTimes <= 2 && queryMerchIdIsInThisClassified > 0) {
          if (!document.getElementById(`Merchandise${this.$route.query.merchandiseId}`)) {
            checkTimes ++
            this.moveToSpecifiedLocation(checkTimes)
            return
          }
          document.getElementById(`Merchandise${this.$route.query.merchandiseId}`).scrollIntoView({
            behavior: 'smooth'
          })
        }
      }, 500)
    },
    // 搜尋商品
    searchMerchandise() {
      if (!this.search) return this.SweetAlert('other', '您尚未輸入商品名稱')
      this.removeClass()
      this.areaLoading = true
      this.$methods.switchLoading('show')
      const vm = this
      const getMerchandisesApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      let methods = '{getMerchandisePictureLinks,getMerchandiseStyles{getMerchandiseDiscounts},getMerchOrders,getMerchClassifications}'
      // 顯示庫存偏好
      if (this.showStock) methods = '{generateMerchandiseStylesStockJsonNode,getMerchandisePictureLinks,getMerchandiseStyles{getMerchandiseDiscounts},getMerchOrders,getMerchClassifications}'
      const data = [
        {
          query: {"columns":[{"columnName":"id","siteObjectType":16}],"group":{"groups":[{"column":{"columnName":"storeId","siteObjectType":16},"operator":{"operator":"=","type":1,"value":this.storeId}},{"column":{"columnName":"name","siteObjectType":16},"operator":{"operator":"like","type":1,"value":`%${this.search}%`}}],"operator":1},"siteObjectType":16,"sqlCommandType":1},
          methods: methods
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getMerchandisesApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchInfo = res.data[0].objects
            vm.$router.push(`/customer/store/${vm.storeId}/oldshop?category=-1`)
            vm.currentCategoryId = -1
            vm.currentCategoryName = '搜尋商品'
            vm.currentCategorySubtitle = ''
            vm.currentCategoryBanner = null
            // 搜尋出來的商品要先經過分類的篩選
            const merchArray = merchInfo.filter(item => {
              console.log(item)
              if (vm.categories.length === 0 && item.merchClassifications.length === 0) return item
              else if (vm.categories.length > 0 && item.merchClassifications.length === 0) return item
              else if (vm.categories.length === 0 && item.merchClassifications.length > 0) console.log('不做處理')
              else if (vm.categories.length > 0 && item.merchClassifications.length > 0) {
                return vm.categories.some(o_category => {
                  return item.merchClassifications.some(i_category => {
                    return o_category.id === i_category.id
                  })
                })
              }
            })
            vm.merchandiseList = []
            vm.sortOutMerchandises(merchArray)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 輪播圖點擊前往連結
    carouselUrl(data) {
      if (data.url) window.open(data.url)
    },
    // 前往下單
    goToOrder(data) {
      this.$router.push({
        name: 'C_OrderPage',
        query: {
          merchandiseId: data.id,
        },
      })
    },
    // * 螢幕寬度 <= 919px 時分類列表的操作 (start)
    // <= 768 時，需要點選分類標籤將分類列表打開
    showCategoryList() {
      $('.category-block').addClass('d-block')
    },
    removeClass() {
      const remove = $('#CategoryBlock').attr('class').split(' ').some(item => {
        return item === 'd-block'
      })
      if (remove) $('#CategoryBlock').removeClass('d-block')
    },
    // * 螢幕寬度 <= 768 時分類列表的操作 (end)
    // 中斷 "取商品" 與 "整理商品"
    breakOff() {
      if (this.getMerhInCategoryFn) this.getMerhInCategoryFn.abort()
      if (this.getMerchandisesFn) this.getMerchandisesFn.abort()
      if (this.sortOutMerchandisesFn) clearTimeout(this.sortOutMerchandisesFn)
    }
  },
}
</script>